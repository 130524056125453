<template>
  <b-card-code
    title="Actualizar Accesorio"
  >
    <b-card-body>
      <FormCreateComponent
        v-if="accessPermissions('accessory.show')"
        :id="id"
        :root="'accessories'"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCardBody,
} from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'AccessoryEditPage',
  components: {
    BCardCode,
    BCardBody,
    FormCreateComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
